import type { PropType, SetupContext } from 'vue'
import type { Row, Cell as _Cell } from '@tanstack/vue-table'
import type { VirtualItem } from '@tanstack/vue-virtual'
import Cell from './Cell'
import { useTableStore } from './composables'

export default defineComponent({
  props: {
    rows: {
      type: Array as PropType<Row<any>[]>,
      required: true,
    },
    virtualRows: {
      type: Array as PropType<VirtualItem[]>,
      default: undefined,
    },
    virtualColumns: {
      type: Array as PropType<VirtualItem[]>,
      default: undefined,
    },
    tableSlots: {
      type: Object as PropType<SetupContext['slots']>,
      required: true,
    },
  },
  emits: ['click:row'],
  setup(props, { emit }) {
    const { rowVirtualizer, hasVirtualRows, hasVirtualColumns, variants } =
      useTableStore()!

    const rows = computed(() => props.virtualRows ?? props.rows)

    const measureElement = (el: any) => {
      nextTick(() => {
        if (!el || !rowVirtualizer.value) return
        rowVirtualizer.value.measureElement(el)
      })
    }

    return () => (
      <tbody
        class={variants.tbody()}
        style={{
          height: `${rowVirtualizer.value?.getTotalSize()}px`, //tells scrollbar how big the table is
        }}
      >
        {rows.value.map((_row) => {
          const virtualRow = hasVirtualRows.value
            ? (_row as VirtualItem)
            : undefined
          const row = virtualRow
            ? props.rows[virtualRow.index]
            : (_row as Row<any>)
          const visibleCells = row.getVisibleCells()
          const isEven = !((virtualRow?.index ?? row.index) & 1)
          const isSubRow = row.depth > 0

          return (
            <tr
              ref={measureElement}
              key={virtualRow?.key ?? row.id}
              data-index={virtualRow?.index}
              class={variants.tbodyTr(
                isSubRow ? { hoverable: false } : undefined,
              )}
              data-even={isEven}
              data-odd={!isEven}
              style={{
                // minHeight: `${virtualRow?.size}px`,
                //this should always be a `style` as it changes on scroll
                transform:
                  rowVirtualizer.value && virtualRow
                    ? `translateY(${virtualRow.start - rowVirtualizer.value.options.scrollMargin}px)`
                    : undefined,
              }}
              onClick={() => emit('click:row', row.original)}
            >
              {/* SubRow Slot */}
              {isSubRow && props.tableSlots.subrow ? (
                <td class={variants.tbodyTd({ isSubRow: true })}>
                  {props.tableSlots.subrow({
                    row,
                    item: row.original,
                    index: virtualRow?.index ?? row.index,
                  })}
                </td>
              ) : (
                <>
                  {/**fake empty column to the left for virtualization scroll padding **/}
                  {hasVirtualColumns.value ? (
                    <td
                      class={variants.tbodyTd()}
                      style={{
                        display: 'var(--virtual-padding-left-display)',
                        width: `calc(var(--virtual-padding-left) * 1px)`,
                      }}
                    />
                  ) : null}
                  {(props.virtualColumns ?? visibleCells).map((_vc, idx) => {
                    const vc = hasVirtualColumns.value
                      ? (_vc as VirtualItem)
                      : undefined
                    const cell = vc
                      ? visibleCells[vc.index]
                      : (_vc as _Cell<any, any>)

                    return (
                      <Cell
                        key={vc?.key ?? cell.id}
                        cell={cell}
                        class={variants.tbodyTd({
                          isLastLeftPinnedColumn:
                            cell.column.getIsLastColumn('left'),
                          isFirstRightPinnedColumn:
                            cell.column.getIsFirstColumn('right'),
                        })}
                      />
                    )
                  })}
                  {/**fake empty column to the left for virtualization scroll padding **/}
                  {hasVirtualColumns.value ? (
                    <td
                      class={variants.tbodyTd()}
                      style={{
                        display: 'var(--virtual-padding-right-display)',
                        width: `calc(var(--virtual-padding-right) * 1px)`,
                      }}
                    />
                  ) : null}
                </>
              )}
            </tr>
          )
        })}
      </tbody>
    )
  },
})
